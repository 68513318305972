define([], () => {

  const responsiveBuildYourOwnBundleProductList = () => {
    const component = {};

    let _config = {
      selectors: {
        productCard: '.responsiveBYOB_productList .productCard',
        headerButton: '.productCategoryHeader',
        headerButtonTitle: '.productCategoryHeader_text_button',
        amountPickedInitial: '.amountPicked_initial',
        amountPickedQuantity: '.amountPicked_quantity',
        amountPickedText: '.productCategoryHeader_amountPicked_text',
        increaseQuantityButton: '.productCard_increaseQuantity',
        decreaseQuantityButton: '.productCard_decreaseQuantity',
        quantityInput: '.productCard_quantitySelector_quantity',
        removeButton: '.productCard_buttons_remove',
        infoButton: '.productCard_buttons_info',
        price: '.productCard_productPrice',
        productCategoryLimitReached: 'productCategoryContent-limitReached',
        tooltip: '.productCategoryContent_tooltip',
        tooltipText: '.productCategoryContent_tooltip_text',
        modalContent: '.modalContent',
        productCategoryContent: '.productCategoryContent',
        modalAccordionItem: '.byobProductDescription_contentPropertyListItem',
        modalAccordionTitle: '.byobProductDescription_accordionControl',
        modalAccordionContent: '.byobProductDescription_contentProperties',
        productLink: '.productCard_productLink',
        opacityWrapper: '.productCard_opacityWrapper',
      },
      dataAttributes: {
        maxProducts: 'data-max-products',
        enableQuantitySelector: 'data-enable-quantity-selector',
        sku: 'data-sku',
        price: 'data-price',
        bundleFullText: 'data-bundle-full-text',
        categoryFullText: 'data-category-full-text',
        aboveLimitText: 'data-above-limit-text',
        belowLimitText: 'data-below-limit-text',
        selectPrefixText: 'data-select-prefix-text',
        unselectPrefixText: 'data-unselect-prefix-text',
        ofText: 'data-of-text',
        selectedText: 'data-selected-text',
      },
      classes: {
        productCardSelected: 'productCard-selected',
        productCardSoldOut: 'productCard-soldOut',
        amountPickedHidden: 'amountPicked_hidden',
        amountPickedSuccess: 'productCategoryHeader_amountPicked-success',
        tooltipHidden: 'productCategoryContent_tooltip-hidden',
        modal: 'responsiveBYOB_modal',
        productCard: 'productCard',
      },
      tooltipTypes: {
        BUNDLE_FULL: 1,
        CATEGORY_FULL: 2,
        ABOVE_LIMIT: 3,
        SECTION_ABOVE_LIMIT: 3,
        BELOW_LIMIT: 4,
      },
      selectedProductsCount: 0,
      isBundleComplete: false,
      isCategoryComplete: false,
      lastInputValue: 1,
      isTooltipShowing: false,
      lastSelectedProduct: 0,
      searchCount: 1,
    };

    const _init = (byobComponent, element, bundleMaxProducts) => {
      component.element = element;

      component.elements = {
        productCards: [...component.element.querySelectorAll(component.config.selectors.productCard)],
        headerButton: component.element.querySelector(component.config.selectors.headerButton),
        headerButtonTitle: component.element.querySelector(component.config.selectors.headerButtonTitle),
        tooltips: [...component.element.querySelectorAll(component.config.selectors.tooltip)],
        productCategoryContent: component.element.querySelector(component.config.selectors.productCategoryContent),
      };

      let maxProducts = component.element.getAttribute(component.config.dataAttributes.maxProducts) || byobComponent.unlimitedProducts ? parseInt(component.element.getAttribute(component.config.dataAttributes.maxProducts)) : 'noLimit';

      component.config.byobComponent = byobComponent;
      component.config.maxProducts = maxProducts;
      component.config.bundleMaxProducts = bundleMaxProducts;
      component.config.enableQuantitySelector = component.element.getAttribute(component.config.dataAttributes.enableQuantitySelector);

      if(component.config.maxProducts) {
        component.elements.limitText = component.element.querySelector(component.config.selectors.amountPickedInitial);
        component.elements.amountPicked = component.element.querySelector(component.config.selectors.amountPickedQuantity);
        component.elements.amountPickedText = component.element.querySelector(component.config.selectors.amountPickedText);
      }

      component.initCards();

      window.addEventListener('click', (e) => {
        if(!e.target.classList.contains(component.config.classes.productCard) &&
           !component.searchParentHelper(e.target, 3, component.config.classes.productCard) &&
           component.config.isTooltipShowing) {
          component.hideAllTooltips();
        }
      });
    };

    const _searchParentHelper = (e, amount, className) => {
      const result = [];

      for(let p = e && e.parentElement; p; p = p.parentElement) {
        result.push(p);
      }

      if(result.length > amount) {
        for(let i = 1; i <= amount; i++) {
          if (result[i].classList.contains(className)) return true;
        }
      }

      return false;
    };

    const _initCards = () => {
      component.elements.productCards.map((el) => {
        if(!el.classList.contains(component.config.classes.productCardSoldOut)) {
          if (component.config.enableQuantitySelector === 'true') {
            el.querySelector(component.config.selectors.increaseQuantityButton)
              .addEventListener('click', (event) => {
                component.increaseQuantity(event, el)
              });
            el.querySelector(component.config.selectors.decreaseQuantityButton)
              .addEventListener('click', (event) => {
                component.decreaseQuantity(event, el)
              });
            el.querySelector(component.config.selectors.quantityInput)
              .addEventListener('change', (event) => {
                component.updateQuantity(event, el);
              });
          }
          el.querySelector(component.config.selectors.removeButton).addEventListener('click', (event) => {
            component.removeProduct(event, el);
          });
          el.addEventListener('click', (event) => {
            component.cardClick(event, el)
          });
          el.querySelector(component.config.selectors.opacityWrapper).addEventListener('click', (event) => {
            component.cardClickUnselect(event, el);
          });
        }
      });
    };

    const _cardClick = (e, el) => {
      e.preventDefault();
      if ((component.config.maxProducts === 'noLimit' || component.config.selectedProductsCount !== component.config.maxProducts)) {
        if (!component.config.isBundleComplete) {
          const sku = parseInt(el.getAttribute(component.config.dataAttributes.sku));
          const price = el.querySelector(component.config.selectors.price) ? parseFloat(el.querySelector(component.config.selectors.price).getAttribute(component.config.dataAttributes.price)) : 0;

          if (!el.classList.contains(component.config.classes.productCardSelected)) {
            component.config.byobComponent.addProduct(sku, 1, price);
            component.config.selectedProductsCount += 1;
            component.config.lastSelectedProduct = el;

            if (component.config.enableQuantitySelector === 'true') {
              el.querySelector(component.config.selectors.quantityInput).value = 1;
              component.config.lastInputValue = 1;
            }

            if (!isNaN(component.config.maxProducts)) {
              component.updateCategoryCount();
              if (component.config.selectedProductsCount === component.config.maxProducts) {
                el.parentElement.parentElement.classList.add(component.config.selectors.productCategoryLimitReached);
                component.config.isCategoryComplete = true;
              }
            }
          }
          component.hideAllTooltips();
          el.classList.add(component.config.classes.productCardSelected);

          const productLink = el.querySelector(component.config.selectors.productLink);
          productLink.setAttribute('aria-label', `${productLink.getAttribute(component.config.dataAttributes.unselectPrefixText)} ${productLink.textContent}`);
        } else {
          component.displayTooltip(component.config.tooltipTypes.BUNDLE_FULL, el);
        }
      } else if(component.config.selectedProductsCount === 1 && component.config.maxProducts === 1) {
        component.config.byobComponent.radioOptionChanged();
        component.removeProduct(null, component.config.lastSelectedProduct);
        if(component.config.selectedProductsCount === 0) {
          component.cardClick(e, el);
          component.isBundleComplete = false;
        }
      } else {
        //bundle limit reached
        component.displayTooltip(component.config.tooltipTypes.CATEGORY_FULL, el);
      }
    };

    const _cardClickUnselect = (e, el) => {
      if(el.classList.contains(component.config.classes.productCardSelected)) {
        const card = el;
        component.removeProduct(e, card);
        const productLink = card.querySelector(component.config.selectors.productLink);
        productLink.setAttribute('aria-label', `${productLink.getAttribute(component.config.dataAttributes.selectPrefixText)} ${productLink.textContent}`);
      }
    };

    const _increaseQuantity = (e, el) => {
      e.stopPropagation();

      if((component.config.maxProducts === 'noLimit' || component.config.selectedProductsCount !== component.config.maxProducts)) {

        if(component.config.isBundleComplete) {
          //limit reached on bundle?
          component.displayTooltip(component.config.tooltipTypes.BUNDLE_FULL, el);
          return
        }

        const sku = parseInt(el.getAttribute(component.config.dataAttributes.sku));
        const priceEle = el.querySelector(component.config.selectors.price);
        const price = parseFloat(priceEle ? priceEle.getAttribute(component.config.dataAttributes.price) : '0');
        const addedProduct = component.config.byobComponent.addProduct(sku, 1, price);

        if(addedProduct) {
          component.config.selectedProductsCount += 1;
          el.querySelector(component.config.selectors.quantityInput).value = addedProduct.quantity;
          component.config.lastInputValue = addedProduct.quantity;

          if(component.config.isTooltipShowing) {
            component.hideAllTooltips();
          }

          if(!isNaN(component.config.maxProducts)) {
            component.updateCategoryCount();
            if(component.config.selectedProductsCount === component.config.maxProducts) {
              el.parentElement.parentElement.classList.add(component.config.selectors.productCategoryLimitReached);
              component.config.isCategoryComplete = true;
            }
          }
        }
      } else {
        //category limit reached
        component.displayTooltip(component.config.tooltipTypes.CATEGORY_FULL, el);
      }
    };

    const _decreaseQuantity = (e, el) => {
      e.stopPropagation();

      const sku = parseInt(el.getAttribute(component.config.dataAttributes.sku));
      const priceEle = el.querySelector(component.config.selectors.price);
      const price = parseFloat(priceEle ? priceEle.getAttribute(component.config.dataAttributes.price) : '0');

      const removedProduct = component.config.byobComponent.removeProduct(sku, 1, price);
      if(removedProduct && component.config.selectedProductsCount >= 0) {
        component.config.selectedProductsCount -= 1;
        el.querySelector(component.config.selectors.quantityInput).value = removedProduct.quantity;
        component.config.lastInputValue = removedProduct.quantity;

        if(component.config.isTooltipShowing) {
          component.hideAllTooltips();
        }

        if(removedProduct.quantity === 0) {
          el.classList.remove(component.config.classes.productCardSelected);
        }

        if(!isNaN(component.config.maxProducts)) {
          component.updateCategoryCount();
          if(component.config.selectedProductsCount < component.config.maxProducts) {
            el.parentElement.parentElement.classList.remove(component.config.selectors.productCategoryLimitReached);
            component.config.isCategoryComplete = false;
          }
        }
      }
    };

    const _validateQuantity = (sku, quantity, el) => {
      const currentSelectedQuantity = component.config.byobComponent.getProductQuantity(sku);
      if (isNaN(quantity) || quantity < 0) {
        component.displayTooltip(component.config.tooltipTypes.BELOW_LIMIT, el);
        return false;
      } else if (typeof (component.config.maxProducts) === 'number'
        && component.config.selectedProductsCount - currentSelectedQuantity + quantity > component.config.maxProducts) {
        component.displayTooltip(component.config.tooltipTypes.SECTION_ABOVE_LIMIT, el);
        return false;
      } else if (component.config.byobComponent.config.unlimitedProducts === 'false'
        && (component.config.byobComponent.config.selectedProductsCount - currentSelectedQuantity + quantity > component.config.bundleMaxProducts)) {
        component.displayTooltip(component.config.tooltipTypes.ABOVE_LIMIT, el);
        return false;
      }
      return true;
    };

    const _updateQuantity = (e, el) => {
      e.stopPropagation();
      e.preventDefault();

      const sku = parseInt(el.getAttribute(component.config.dataAttributes.sku));
      const priceEle = el.querySelector(component.config.selectors.price);
      const price = parseFloat(priceEle ? priceEle.getAttribute(component.config.dataAttributes.price) : '0');
      let quantity = el.querySelector(component.config.selectors.quantityInput).valueAsNumber;

      if (component.validateQuantity(sku, quantity, el)) {
        //remove the product form the bundle first
        const removedProducts = component.config.byobComponent.removeProduct(sku, 'all', price);
        if (removedProducts && component.config.selectedProductsCount >= 0) {
          component.config.selectedProductsCount -= removedProducts.removedQuantity;
          if (typeof(component.config.maxProducts) === 'number') {
            component.updateCategoryCount();
            if(component.config.selectedProductsCount < component.config.maxProducts) {
              el.parentElement.parentElement.classList.remove(component.config.selectors.productCategoryLimitReached);
              component.config.isCategoryComplete = false;
            }
          }
        }

        if(component.config.isTooltipShowing) {
          component.hideAllTooltips();
        }

        if (quantity === 0) {
          el.classList.remove(component.config.classes.productCardSelected);
          return;
        }
        //now add it again with the updated quantity
        const addedProduct = component.config.byobComponent.addProduct(sku, quantity, price);
        if (addedProduct) {
          component.config.selectedProductsCount += quantity;
          if (typeof(component.config.maxProducts) === 'number') {
            component.updateCategoryCount();
            if(component.config.selectedProductsCount === component.config.maxProducts) {
              el.parentElement.parentElement.classList.add(component.config.selectors.productCategoryLimitReached);
              component.config.isCategoryComplete = true;
            }
          }
          component.config.lastInputValue = quantity;
        }
      } else {
        el.querySelector(component.config.selectors.quantityInput).value = component.config.lastInputValue;
      }
    };

    const _removeProduct = (e, el) => {
      if(e) e.stopPropagation();

      const sku = parseInt(el.getAttribute(component.config.dataAttributes.sku));
      const quantityRemoved = component.config.byobComponent.removeProduct(sku, 'all', 0).removedQuantity;

      component.config.selectedProductsCount -= quantityRemoved;
      el.classList.remove(component.config.classes.productCardSelected);

      if(component.config.isTooltipShowing) {
        component.hideAllTooltips();
      }

      if(!isNaN(component.config.maxProducts)) {
        component.updateCategoryCount();
        if(component.config.selectedProductsCount < component.config.maxProducts) {
          el.parentElement.parentElement.classList.remove(component.config.selectors.productCategoryLimitReached);
          component.config.isCategoryComplete = false;
        }
      }
    };

    const _updateCategoryCount = () => {
      if(!isNaN(component.config.maxProducts)) {
        if(component.config.selectedProductsCount > 0) {
          component.elements.amountPicked.classList.remove(component.config.classes.amountPickedHidden);
          component.elements.limitText.classList.add(component.config.classes.amountPickedHidden);
          const ofText =  component.elements.amountPickedText.getAttribute(component.config.dataAttributes.ofText);
          const selectedText =  component.elements.amountPickedText.getAttribute(component.config.dataAttributes.selectedText);
          component.elements.amountPickedText.innerText = `${component.config.selectedProductsCount} ${ofText} ${component.config.maxProducts} ${selectedText}`;

          if (component.config.selectedProductsCount === component.config.maxProducts) {
            component.elements.amountPicked.classList.add(component.config.classes.amountPickedSuccess);
          } else {
            component.elements.amountPicked.classList.remove(component.config.classes.amountPickedSuccess);
          }
        } else {
          component.elements.amountPicked.classList.add(component.config.classes.amountPickedHidden);
          component.elements.limitText.classList.remove(component.config.classes.amountPickedHidden);
        }
      }
    };

    const _setBundleCategoryStatus = (status) => {
      if (status === 'limitReached') {
        component.config.isBundleComplete = true;
        component.elements.productCategoryContent.classList.add(component.config.selectors.productCategoryLimitReached);
      } else {
        component.config.isBundleComplete = false;
        if(!component.config.isCategoryComplete) {
          component.elements.productCategoryContent.classList.remove(component.config.selectors.productCategoryLimitReached);
        }
        component.hideAllTooltips();
      }
    };

    const _getTitle = () => {
      return component.elements.headerButtonTitle.innerText;
    };

    const _hideAllTooltips = () => {
      component.elements.tooltips.map((el) => {
        el.classList.remove(component.config.classes.tooltipHidden);
        el.classList.add(component.config.classes.tooltipHidden);
      });
      component.config.isTooltipShowing = false;
    };

    const _displayTooltip = (tooltipType, el) => {
      component.hideAllTooltips();

      const tooltip = el.querySelector(component.config.selectors.tooltip);
      tooltip.classList.remove(component.config.classes.tooltipHidden);

      const tooltipText = el.querySelector(component.config.selectors.tooltipText);

      switch(tooltipType) {
        case component.config.tooltipTypes.BUNDLE_FULL:
          tooltipText.innerText = component.element.getAttribute(component.config.dataAttributes.bundleFullText);
          break;
        case component.config.tooltipTypes.CATEGORY_FULL:
          tooltipText.innerText = component.element.getAttribute(component.config.dataAttributes.categoryFullText);
          break;
        case component.config.tooltipTypes.ABOVE_LIMIT:
        case component.config.tooltipTypes.SECTION_ABOVE_LIMIT:
          let text = component.element.getAttribute(component.config.dataAttributes.aboveLimitText);
          tooltipText.innerText = text.replace('[x]', component.config.byobComponent.getRemainingLimit());
          break;
        case component.config.tooltipTypes.BELOW_LIMIT:
          tooltipText.innerText = component.element.getAttribute(component.config.dataAttributes.belowLimitText);
          break;

      }
      component.config.isTooltipShowing = true;
    };

    component.config = _config;

    component.searchParentHelper = _searchParentHelper;
    component.cardClick = _cardClick;
    component.cardClickUnselect = _cardClickUnselect;
    component.increaseQuantity = _increaseQuantity;
    component.decreaseQuantity = _decreaseQuantity;
    component.updateQuantity = _updateQuantity;
    component.removeProduct = _removeProduct;
    component.updateCategoryCount = _updateCategoryCount;
    component.validateQuantity = _validateQuantity;
    component.setBundleCategoryStatus = _setBundleCategoryStatus;
    component.hideAllTooltips = _hideAllTooltips;
    component.displayTooltip = _displayTooltip;
    component.getTitle = _getTitle;
    component.initCards = _initCards;
    component.init = _init;

    return component;
  };

  return responsiveBuildYourOwnBundleProductList;
});
